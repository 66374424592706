<template>
  <div>
    <span v-intersect="onIntersect"></span>
    <v-chip-group v-if="!loading">
      <RoomChip
        v-for="room in rooms"
        :key="'room' + room.id"
        :value="room"
      ></RoomChip>
      <ReservationChip
        v-for="reservation in reservations"
        :key="'reservation' + reservation.id"
        :value="reservation"
      ></ReservationChip>
    </v-chip-group>
    <v-chip v-else small outlined
      ><v-progress-circular
        size="14"
        width="2"
        indeterminate
      ></v-progress-circular
    ></v-chip>
  </div>
</template>

<script>
import ReservationChip from "common/components/ReservationChip.vue";
import RoomChip from "common/components/RoomChip.vue";

export default {
  name: "SmartLearnRoom",
  components: { ReservationChip, RoomChip },
  props: {
    date: String,
    period: { startTime: null, endTime: null },
    course: {},
    schoolClass: {},
    person: {},
  },
  data() {
    return {
      rooms: [],
      reservations: [],
      loading: false,
      visible: false,
    };
  },
  methods: {
    onIntersect(entries) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      if (!this.visible && this.isIntersecting) {
        this.visible = true;
        this.fetchData();
      }
    },
    async fetchData() {
      if (!this.date || !this.period) {
        return;
      }
      this.loading = true;
      if (this.schoolClass && this.schoolClass.id) {
        this.register = await this.apiList({
          resource: "register/register",
          query: `schoolClass=${this.schoolClass.id}&startDate=${this.date}&startTime=${this.period.startTime}&endDate=${this.date}&endTime=${this.period.endTime}`,
        });
      } else if (this.person && this.person.id) {
        const type =
          this.person.type.code === "student" ? "student" : "teacher";
        this.register = await this.apiList({
          resource: "register/register",
          query: `${type}=${this.person.id}&startDate=${this.date}&startTime=${this.period.startTime}&endDate=${this.date}&endTime=${this.period.endTime}`,
        });
      }
      this.rooms = this.register.days[0].lessons[0].rooms;
      this.reservations = this.register.days[0].lessons[0].reservations;
      this.loading = false;
    },
  },
};
</script>
